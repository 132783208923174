import React, { useEffect, useState } from 'react';
import './style.css';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../Header/Header';
import ServerConnect from '../../service';
import { setActivePanel, setBurgerInfo, setIsActiveNavbar } from '../../store/mainReducer';

const TicketInfo = ({setBackTicketInfo}) => {
  const dispatch = useDispatch();
  const [classPage, setClassPage] = useState('burger');
  const points = useSelector(state => state.main.userInfo.points);
  const currentTicketInfo = useSelector(state => state.main.currentTicketInfo);
  const currentTicketTitle = useSelector(state => state.main.currentTicketTitle);


  useEffect(() => {
    setBackTicketInfo(false);
    dispatch(setIsActiveNavbar(true));
    window.scrollTo({top: 0, behavior: 'smooth'});
    setTimeout(() => {
      setClassPage('burger burger_active');
    }, 240)
  }, [])
    return (
      <div className={`container ${classPage}`}>
        <img
          className="page-background"
          src="assets/images/main-bg.png"
          alt=""
        />
        <div className='header'>
          <img onClick={() => {
              dispatch(setActivePanel('kiosk'));
              setBackTicketInfo(true);
            }} style={{width: '23px'}} className='header__burger-icon' src='assets/images/back-arrow.svg' alt="" />
          <img className='header__logo' src="assets/images/header-logo.png" alt="" />
          <div className='header__count-wrapper'>
            <img className='header__count-border' src="assets/images/hader-count-border.png" alt="" />
            <p className='header__count-number'>
              {points}
            </p>
            <img className='header__coint' src="assets/images/header-coin.png" alt="" />
          </div>
        </div>
        <div style={{display: 'block'}} className='burger__history'>
          <div className='burger__history-content'>
            <p className='ticket-info__title'>
              {currentTicketTitle}
            </p>
            {currentTicketInfo.map((ticket, index) => {
              return(
                <div key={index} className='ticket-info__item'>
                  <img className='ticket-info__item-icon' src="assets/images/ticket-info-icon.svg" alt="" />
                  <p className='ticket-info__item-subtitle-other-1234'>
                    билет на розыгрыш:
                  </p>
                  <p className='ticket-info__item-points'>{ticket}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
}

export default TicketInfo;