import React, { useState } from 'react'
import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux"
import { setActivePanel } from "../../store/mainReducer";
import './style.css';
import navbarFriends from '../../../public/assets/images/static/navbar-friends.svg';
import navbarFriendsActive from '../../../public/assets/images/static/navbar-friends-active.svg';
import navbarTasks from '../../../public/assets/images/static/navbar-tasks.svg';
import navbarTasksActive from '../../../public/assets/images/static/navbar-tasks-active.svg';

const Navbar = ({isActiveNavbar}) => {
    const currentScreen = useSelector((state) => state.main.activePanel);
    const dispatch = useDispatch();
    useEffect(() => {
      if (currentScreen == 'kiosk') {
        setClassButtons({
          'kiosk': {
            class: 'navbar__kiosk-block navbar__kiosk-block_active',
          },
          'friends': {
            class: 'navbar__friends-block',
            src: navbarFriends
          },
          'tasks': {
            class: 'navbar__tasks-block',
            src: navbarTasks
          },
        })
      }
      else if (currentScreen == 'tasks') {
        setClassButtons({
          'kiosk': {
            class: 'navbar__kiosk-block',
          },
          'friends': {
            class: 'navbar__friends-block',
            src: navbarFriends
          },
          'tasks': {
            class: 'navbar__tasks-block navbar__tasks-block_active',
            src: navbarTasksActive
          },
        })
      }
      else if (currentScreen == 'friends') {
        setClassButtons({
          'kiosk': {
            class: 'navbar__kiosk-block',
          },
          'friends': {
            class: 'navbar__friends-block navbar__friends-block_active',
            src: navbarFriendsActive
          },
          'tasks': {
            class: 'navbar__tasks-block',
            src: navbarTasks
          },
        })
      }
    }, [currentScreen])
    const [classButtons, setClassButtons] = useState({
      'kiosk': {
        class: 'navbar__kiosk-block',
      },
      'friends': {
        class: 'navbar__friends-block',
        src: navbarFriends
      },
      'tasks': {
        class: 'navbar__tasks-block',
        src: navbarTasks
      },
    });
    function buttonHandler(name) {
      dispatch(setActivePanel(name));
    }

    return (
        <div style={{display: !isActiveNavbar ? 'none': 'flex'}} className='navbar'>
          <img className='navbar__menu-blur' src="assets/images/menu-blur.png" alt="" />
          <div onClick={() => buttonHandler('friends')} className={classButtons.friends.class}>
            <img className='navbar__friends-icon' src={classButtons.friends.src} alt="" />
            <p className='navbar__friends-text'>
              Друзья
            </p>
          </div>
          <div onClick={() => buttonHandler('tasks')} className={classButtons.tasks.class}>
            <img className='navbar__tasks-icon' src={classButtons.tasks.src} alt="" />
            <p className='navbar__tasks-text'>
              Задания
            </p>
          </div>
          <div onClick={() => buttonHandler('kiosk')} className={classButtons.kiosk.class}>
            <div className='navbar__kiosk-wrapper'>
              <img className='navbar__kiosk-img' src="assets/images/navbar-kiosk-active.svg" alt="" />
            </div>
          </div>
        </div>
    );
}


export default Navbar;