import React, { useEffect, useState } from 'react';
import './style.css';
import { useDispatch, useSelector } from 'react-redux';
import { setActivePanel, setBubbles, setFirstUse, setTgId, setUserInfo } from '../../store/mainReducer';
import ServerConnect from '../../service';
import { isMobile } from 'react-device-detect';

const Loading = () => {
  const dispatch = useDispatch();
  const [classPage, setClassPage] = useState('loading loading_active');

  function parseQuery(queryString) {
    let query = {};
    let pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
    for (let i = 0; i < pairs.length; i++) {
        let pair = pairs[i].split('=');
        query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
    return query;
  }
  let userChatId;
  
  useEffect(() => {
    setClassPage('loading loading_active');

    let app = window.Telegram.WebApp;
    let query = app.initData;
    let user_data_str = parseQuery(query).user;
    let user_data = JSON.parse(user_data_str);
    app.disableVerticalSwipes();
    app.expand();
    app.ready();
    app.setHeaderColor("#200E0E")
    app.setBackgroundColor("#200E0E")
    app.setBottomBarColor("#200E0E")
    userChatId = user_data["id"];

    const images = [
      "assets/images/kiosk-gavr-1.png",
      "assets/images/kiosk-gavr-2.png",
      "assets/images/kiosk-gavr-3.png",
      "assets/images/onboarding-1-actors.png",
    ];

    const preload = () => {
      const promises = images.map((src) => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.src = src;
          img.onload = resolve; // Успешная загрузка
          img.onerror = reject; // Ошибка загрузки
        });
      });

      Promise.all(promises)
        .then(() => {
          
        })
        .catch((error) => console.error("Error loading images:", error));
    };
    preload();

    

    dispatch(setTgId(userChatId));
    ServerConnect.getUser('/api/get_user_data/', userChatId)
      .then((data) => {
        console.log(data);
        dispatch(setUserInfo({
          tgId: data.user_data.user_id,
          username: data.user_data.username,
          points: data.user_data.points,
          invitedFriends: data.referral_count,
        }));
        if (data.start_id && !data.have_auth_data) {
          ServerConnect.postStart()
          .then((startData) => {
            ServerConnect.authRefresh('/api/auth_refresh/', {
              front_handler_response: startData,
              user_id: data.user_data.user_id
            })
              .then((data) => {})
              .catch(err => {})
          })
          .catch((startError) => {
            ServerConnect.authRefresh('/api/auth_refresh/', {
              front_handler_response: startError,
              user_id: data.user_data.user_id
            })
              .then((data) => {})
              .catch(err => {})
          })
        }
        dispatch(setBubbles(data.text_aunt));
        dispatch(setFirstUse(data.first_use));
        if (!data.first_use) {
          setTimeout(() => {
            setClassPage('loading')
            setTimeout(() => {
              dispatch(setActivePanel('onboarding-1'))
            }, 240)
          }, 2760)
        }
        else {
          setTimeout(() => {
            setClassPage('loading')
            setTimeout(() => {
              dispatch(setActivePanel('onboarding-1'))
            }, 240)
          }, 2760)
        }
      })
      .catch((err) => {
        console.log(err);
      })
  }, [])
    return (
      <div className={`container ${classPage}`}>
        {/* <img className='loading__logo' src="assets/images/loading-logo1234.png" alt="" /> */}
        {/* <img className='loading__uzhe' src="assets/images/uzhe-na-start.png" alt="" /> */}
        {/* <img className='loading__kiosk' src="assets/images/onboarding1-kiosk.png" alt="" /> */}
        <img className='loading__shadow' src="assets/images/loading-shadow-2.png" alt="" />
        <div className='loading-wrapper'>
          <div className='loading-current'></div>
        </div>
      </div>
    );
}

export default Loading;