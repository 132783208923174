import React, { useEffect, useRef, useState } from 'react';
import './style.css';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../Header/Header';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import ServerConnect from '../../service';
import { setActivePanel, setActivities, setCurrentTask, setFriends, setUserInfo } from '../../store/mainReducer';

const Tasks = () => {
  const dispatch = useDispatch();
  const [classPage, setClassPage] = useState('tasks');
  const userInfo = useSelector(state => state.main.userInfo);
  const activities = useSelector(state => state.main.activities);
  const currentTask = useSelector(state => state.main.currentTask);
  const friends = useSelector(state => state.main.friends);
  console.log(activities)

  const swiperRef = useRef(null);

  const [disabledCheckSubBtn, setDisabledCheckSubBtn] = useState(false);
  const [disabledTextBtn, setDisabledTextBtn] = useState('Проверить');

  const [timer, setTimer] = useState(5);
  const [intervalId, setIntervalId] = useState(null);

  const startTimer = () => {
    // Если таймер уже запущен, не делаем ничего
    if (intervalId) return;

    // Устанавливаем интервал
    const id = setInterval(() => {
      setTimer(prevState => {
        if (prevState > 0) {
          return prevState - 1; // Уменьшаем таймер на 1
        } else {
          clearInterval(id); // Очищаем интервал при достижении 0
          setTimer(0);
          setDisabledCheckSubBtn(false);
          setDisabledTextBtn('Получить START-коины');
          return 0;
        }
      });
    }, 1000); // Каждую секунду

    setIntervalId(id); // Сохраняем идентификатор интервала
  };

  const [subPopup, setSubPopup] = useState('tasks__sub-popup');
  const [subPopupConfig, setSubPopupConfig] = useState({
    coins: 0,
    title: '',
    subtitle: '',
  });
  const [subMonthPopupConfig, setSubMonthPopupConfig] = useState({
    coins: 0,
    title: '',
    subtitle: '',
  });
  const [subMonthPopup, setSubMonthPopup] = useState("tasks__sub-month");
  const [successPopup, setSuccessPopup] = useState('success-popup');
  const [errorPopup, setErrorPopup] = useState("error-popup");
  const [friendsPopup, setFriendsPopup] = useState('friends-popup');
  const [navItem, setNavItem] = useState(1);

  const [snackbar, setSnackbar] = useState('snackbar');

  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
    setTimeout(() => {
      setClassPage('tasks tasks_active');
    }, 240)

    ServerConnect.getActivities('/api/get_active_list/', userInfo.tgId)
      .then((data) => {
        console.log(data);
        dispatch(setActivities(data));
        setTimeout(() => {
          if (swiperRef.current) {
            // Выбираем второй слайд как активный
            swiperRef.current.swiper.slideTo(data.current_daily - 1);
          }
        }, 500)
      })
      .catch((error) => {
        console.log(error);
      })

    ServerConnect.getFriends('/api/get_invites/', userInfo.tgId)
      .then((data) => {
        console.log(data);
        dispatch(setFriends(data));
      })
      .catch((err) => {
        console.log(err)
      })

  }, [])

  function buyHandler(productId, count) {
    ServerConnect.buyProduct('/api/post_buy_product/', {
        user_id: userInfo.tgId,
        product_id: productId,
        count: count,
      })
      .then((data) => {
        console.log(data);
        if (data.delete_cookies) {
          document.cookie.split(';').forEach(c => document.cookie = c.split('=')[0]+'=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/');
        }
        if (data.redirect_url) {
          window.location.href = data.redirect_url;
        }
        ServerConnect.getActivities('/api/get_active_list/', userInfo.tgId)
        .then((data) => {
          console.log(data);
          dispatch(setActivities(data))
        })
        .catch((error) => {
          console.log(error);
        })
  
        ServerConnect.getUser('/api/get_user_data/', userInfo.tgId)
        .then((data) => {
          console.log(data);
          dispatch(setUserInfo({
            tgId: data.user_data.user_id,
            username: data.user_data.username,
            points: data.user_data.points,
            invitedFriends: data.referral_count,
          }));})
        .catch(err => console.log(err))
      })
      .catch((err) => {
        console.log(err);
      })
  }
  function checkHandler(productId, count) {
    if (currentTask.front_handler) {
      ServerConnect.postStart()
        .then((startData) => {
          ServerConnect.buyProduct('/api/post_buy_product/', {
            user_id: userInfo.tgId,
            product_id: productId,
            count: count,
            front_handler_response: startData
          })
          .then((data) => {
            if (data.delete_cookies) {
              document.cookie.split(';').forEach(c => document.cookie = c.split('=')[0]+'=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/');
            }
            if (data.redirect_url) {
              window.location.href = data.redirect_url;
            }
            console.log(data);
            if (data.status === 'Condition not complete') {
              setTimeout(() => {
                setErrorPopup('error-popup error-popup_active');
              }, 0)
            }
           if (data.status === "Purchase confirmed") {
            setTimeout(() => {
              setSuccessPopup('success-popup success-popup_active');
            }, 0)
           }
            ServerConnect.getActivities('/api/get_active_list/', userInfo.tgId)
            .then((data) => {
              console.log(data);
              dispatch(setActivities(data))
            })
            .catch((error) => {
              console.log(error);
            })
      
            ServerConnect.getUser('/api/get_user_data/', userInfo.tgId)
            .then((data) => {
              console.log(data);
              dispatch(setUserInfo({
                tgId: data.user_data.user_id,
                username: data.user_data.username,
                points: data.user_data.points,
                invitedFriends: data.referral_count,
              }));})
            .catch(err => console.log(err))
          })
          .catch((err) => {
            console.log(err);
          })
        })
        .catch((startError) => {
          ServerConnect.buyProduct('/api/post_buy_product/', {
            user_id: userInfo.tgId,
            product_id: productId,
            count: count,
            front_handler_response: startError
          })
          .then((data) => {
            if (data.delete_cookies) {
              document.cookie.split(';').forEach(c => document.cookie = c.split('=')[0]+'=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/');
            }
            if (data.redirect_url) {
              window.location.href = data.redirect_url;
            }
            console.log(data);
            if (data.status === 'Condition not complete') {
              setTimeout(() => {
                setErrorPopup('error-popup error-popup_active');
              }, 0)
            }
           if (data.status === "Purchase confirmed") {
            setTimeout(() => {
              setSuccessPopup('success-popup success-popup_active');
            }, 0)
           }
            ServerConnect.getActivities('/api/get_active_list/', userInfo.tgId)
            .then((data) => {
              console.log(data);
              dispatch(setActivities(data))
            })
            .catch((error) => {
              console.log(error);
            })
      
            ServerConnect.getUser('/api/get_user_data/', userInfo.tgId)
            .then((data) => {
              console.log(data);
              dispatch(setUserInfo({
                tgId: data.user_data.user_id,
                username: data.user_data.username,
                points: data.user_data.points,
                invitedFriends: data.referral_count,
              }));})
            .catch(err => console.log(err))
          })
          .catch((err) => {
            console.log(err);
          })
        })
    }
    else {
      ServerConnect.buyProduct('/api/post_buy_product/', {
        user_id: userInfo.tgId,
        product_id: productId,
        count: count,
      })
      .then((data) => {
        if (data.delete_cookies) {
          document.cookie.split(';').forEach(c => document.cookie = c.split('=')[0]+'=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/');
        }
        if (data.redirect_url) {
          window.location.href = data.redirect_url;
        }
        console.log(data);
        if (data.status === 'Condition not complete') {
          setTimeout(() => {
            setErrorPopup('error-popup error-popup_active');
          }, 0)
        }
       if (data.status === "Purchase confirmed") {
        setTimeout(() => {
          setSuccessPopup('success-popup success-popup_active');
        }, 0)
       }
        ServerConnect.getActivities('/api/get_active_list/', userInfo.tgId)
        .then((data) => {
          console.log(data);
          dispatch(setActivities(data))
        })
        .catch((error) => {
          console.log(error);
        })
  
        ServerConnect.getUser('/api/get_user_data/', userInfo.tgId)
        .then((data) => {
          console.log(data);
          dispatch(setUserInfo({
            tgId: data.user_data.user_id,
            username: data.user_data.username,
            points: data.user_data.points,
            invitedFriends: data.referral_count,
          }));})
        .catch(err => console.log(err))
      })
      .catch((err) => {
        console.log(err);
      })
    }
  }
    return (
      <div className={`container ${classPage}`}>
        <Header />
        <div className={friendsPopup}>
          <div className={snackbar}>
            Ссылка скопирована
          </div>
          <img onClick={() => setFriendsPopup('friends-popup')} className='tasks__sub-close' src="assets/images/close-icon.svg" alt="" />
          <div className='tasks__sub-coins'>
            <img className='tasks__sub-border' src="assets/images/tasks-sub-popup-border.png" alt="" />
            <p className='tasks__sub-count'>+{currentTask.cost}</p>
            <img className='tasks__sub-coin' src="assets/images/header-coin.png" alt="" />
          </div>
          <p className='tasks__sub-title'>
            {currentTask.title}
          </p>
          <p className='tasks__sub-subtitle'>
            {currentTask.description}
          </p>
          <div className='tasks__sub-buttons'>
            <div className='friends-popup__top-buttons'>
              <button onClick={() => {
                  window.open(`https://t.me/share/url?url=${friends.link}`, '_blank');
                }} className='friends-popup__refer-link' type='button'>Пригласить</button>
              <div onClick={() => {
                navigator.clipboard.writeText(friends.link);
                setSnackbar('snackbar snackbar_active');
                setTimeout(() => {
                  setSnackbar('snackbar')
                }, 2000)
              }} className='friends-popup__copy-btn'>
                <img className='friends__popup__copy-img' src="assets/images/copy-icon.svg" alt="" />
              </div>
            </div>
            <button onClick={() => {
              setFriendsPopup('friends-popup');
              // setTimeout(() => {
              //   setSuccessPopup('success-popup success-popup_active');
              // }, 200)
              checkHandler(currentTask.task_id, 1)
              }} className='tasks__check-btn' type='button'>
              Проверить
            </button>
          </div>
        </div>
        <div className={errorPopup}>
          <img className='error-popup__img' src="assets/images/no-purchases-casset-2.png" alt="" />
          <p className='error-popup__title'>
            {currentTask.title.includes('Смотреть') && currentTask.title.includes('серию') ? <>Коины за&nbsp;просмотр начислятся завтра</> : <>Что-то пошло<br />не&nbsp;так</>}
          </p>
          <p className='error-popup__subtitle'>
          {currentTask.title.includes('Смотреть') && currentTask.title.includes('серию') ? <>Не&nbsp;забудь повторно нажать на&nbsp;кнопку &laquo;Проверить&raquo;</> : <>Проверь правила выполнения задания в&nbsp;разделе &laquo;Вопросы и&nbsp;ответы&raquo;.</> }
          </p>
          <button onClick={() => setErrorPopup('error-popup')} className='success-popup__close-btn' type='button'>
            Закрыть
          </button>
        </div>
        <div className={successPopup}>
          <div className='success-popup__info'>
            <img className='success-popup__info-bg' src="assets/images/debt-dialog.png" alt="" />
            <p className='success-popup__info-title'>
              Деньги<br />капнули!
            </p>
            <div style={{margin: '0 auto', position: 'relative', zIndex: '10'}} className='header__count-wrapper'>
              <img className='header__count-border' src="assets/images/success-popup-bg-coins.png" alt="" />
              <p className='header__count-number'>
                +{currentTask.cost}
              </p>
              <img className='header__coint' src="assets/images/header-coin.png" alt="" />
            </div>
          </div>
          <img className='success-popup__coins' src="assets/images/onboarding2-coins-main.png" alt="" />
          <img className='success-popup__zina' src="assets/images/debt-popup-zina.png" alt="" />
          <button onClick={() => setSuccessPopup('success-popup')} className='success-popup__close-btn' type='button'>
            Закрыть
          </button>
          <button onClick={() => {
              setClassPage('tasks');
              setTimeout(() => {
                dispatch(setActivePanel('burger'));
              }, 200)
            }} className='success-popup__history-btn' type='button'>
            История&nbsp;начислений
          </button>
        </div>
        <div className={subPopup}>
          <img onClick={() => setSubPopup('tasks__sub-popup')} className='tasks__sub-close' src="assets/images/close-icon.svg" alt="" />
          <div className='tasks__sub-coins'>
            <img className='tasks__sub-border' src="assets/images/tasks-sub-popup-border.png" alt="" />
            <p className='tasks__sub-count'>+{currentTask.cost}</p>
            <img className='tasks__sub-coin' src="assets/images/header-coin.png" alt="" />
          </div>
          <p className='tasks__sub-title'>
            {currentTask.title}
          </p>
          <p className='tasks__sub-subtitle'>
            {currentTask.description}
          </p>
          <div className='tasks__sub-buttons'>
            <button onClick={() => {
                  if (currentTask.redirect) {
                    window.location.href = currentTask.link;
                  }
                  else {
                    window.open(currentTask.link, '_blank')
                  }
                }
              } className='tasks__sub-link' type='button'>
              <p className='tasks__sub-link-text'>Перейти</p>
              <img className='tasks__sub-link-img' src="assets/images/link-arrow.svg" alt="" />
            </button>
            <button onClick={() => {
              setSubPopup('tasks__sub-popup');
              // setTimeout(() => {
              //   setSuccessPopup('success-popup success-popup_active');
              // }, 200)
              checkHandler(currentTask.task_id, 1)
              }} className='tasks__check-btn' type='button'>
              Проверить
            </button>
          </div>
        </div>
        <div className={subMonthPopup}>
          <img onClick={() => setSubMonthPopup('tasks__sub-month')} className='tasks__sub-close' src="assets/images/close-icon.svg" alt="" />
          <div className='tasks__sub-coins'>
            <img className='tasks__sub-border' src="assets/images/tasks-sub-popup-border.png" alt="" />
            <p className='tasks__sub-count'>+{currentTask.cost}</p>
            <img className='tasks__sub-coin' src="assets/images/header-coin.png" alt="" />
          </div>
          <p className='tasks__sub-title'>
            {currentTask.title}
          </p>
          <p className='tasks__sub-subtitle'>
            {currentTask.description}
          </p>
          <div style={{opacity: disabledCheckSubBtn ? '1' : '0'}} className='tasks__sub-timer'>
            Проверяем задание<br />
            {timer}
          </div>
          <div className='tasks__sub-buttons'>
            <button onClick={() => {
                  if (currentTask.redirect) {
                    window.location.href = currentTask.link;
                  }
                  else {
                    window.open(currentTask.link, '_blank')
                  }
                }
              } className='tasks__sub-link' type='button'>
              <p className='tasks__sub-link-text'>Перейти</p>
              <img className='tasks__sub-link-img' src="assets/images/link-arrow.svg" alt="" />
            </button>
            <button onClick={() => {
              // if (disabledTextBtn === 'Получить START-коины') {
              //   setSubMonthPopup('tasks__sub-month')
              //   checkHandler(currentTask.task_id, 1)
              // }
              // else {  
              //   startTimer();
              //   setDisabledCheckSubBtn(true);
              // }
                setSubMonthPopup('tasks__sub-month')
                checkHandler(currentTask.task_id, 1)
              }} className='tasks__check-btn' type='button'>
              {disabledTextBtn}
            </button>
          </div>
        </div>
        <img
          className="page-background"
          src="assets/images/main-bg.png"
          alt=""
        />
        <div className='tasks__swiper-block'>
          <p className='tasks__swiper-title'>
            Ежедневные награды
          </p>
          <Swiper
            ref={swiperRef}
            spaceBetween={4}
            slidesPerView={'auto'}
          >
            {[...activities.daily_list].map((elem, index) => {
                if (activities.current_daily == elem.order) {
                  if (activities.current_daily_received) {
                    return(
                      <SwiperSlide key={elem.daily_id}>
                        <div className='tasks__swiper-item tasks__swiper-item_complete'>
                          <img className='tasks__swiper-complete-img' src="assets/images/swiper-complete.svg" alt="" />
                          <p className='tasks__swiper-complete-text'>
                            Получено
                          </p>
                          <img className='tasks__swiper-complete-bg' src="assets/images/swiper-complete-bg.png" alt="" />
                        </div>
                      </SwiperSlide>
                    );
                  }
                  else {
                    return(
                      <SwiperSlide key={elem.daily_id}>
                        <div onClick={() => buyHandler(elem.daily_id, 1)} className='tasks__swiper-item tasks__swiper-item_current'>
                          <div style={{margin: '0 auto 6px auto'}} className='header__count-wrapper'>
                            <img className='header__count-border' src="assets/images/hader-count-border.png" alt="" />
                            <p className='header__count-number'>
                              +{elem.cost}
                            </p>
                            <img className='header__coint' src="assets/images/header-coin.png" alt="" />
                          </div>
                          <p className='tasks__swiper-current-text'>
                            Получить
                          </p>
                          <img className='tasks__swiper-current-bg' src="assets/images/swiper-current.png" alt="" />
                        </div>
                      </SwiperSlide>
                    );
                  }
                }
                if (activities.current_daily > elem.order) {
                      return(<SwiperSlide key={elem.daily_id}>
                        <div className='tasks__swiper-item tasks__swiper-item_complete'>
                          <img className='tasks__swiper-complete-img' src="assets/images/swiper-complete.svg" alt="" />
                          <p className='tasks__swiper-complete-text'>
                            Получено
                          </p>
                          <img className='tasks__swiper-complete-bg' src="assets/images/swiper-complete-bg.png" alt="" />
                        </div>
                      </SwiperSlide>)
                }
                if (activities.current_daily < elem.order) {
                  return(
                    <SwiperSlide key={elem.daily_id}>
                      <div className='tasks__swiper-item tasks__swiper-item_next'>
                        <div style={{margin: '0 auto 6px auto'}} className='header__count-wrapper'>
                          <img className='header__count-border' src="assets/images/border-current-oth-col.png" alt="" />
                          <p className='header__count-number'>
                            +{elem.cost}
                          </p>
                          <img className='header__coint' src="assets/images/header-coin.png" alt="" />
                        </div>
                        <p className='tasks__swiper-next-text'>
                          День {elem.order}
                        </p>
                        <img className='tasks__swiper-next-bg' src="assets/images/swiper-next.png" alt="" />
                      </div>
                    </SwiperSlide>
                  );
                }
            })}
          </Swiper>
        </div>
        <div className="tasks__content">
          <p className='tasks__content-title'>
            Заработать START-коины
          </p>
          <div className='tasks__content-navbar'>
            <div onClick={() => setNavItem(1)} className={navItem === 1 ? 'tasks__content-item tasks__content-item_active' : 'tasks__content-item'}>
              активности
            </div>
            <div onClick={() => setNavItem(2)} className={navItem === 2 ? 'tasks__content-item tasks__content-item_active' : 'tasks__content-item'}>
              задания
            </div>
          </div>
          <div style={{display: navItem === 1 ? 'block' : 'none'}} className='tasks__activity-list'>
          {[...activities.active_list].map((elem, index) => {
            return(
              <div key={index} className='tasks__activity'>
                <div className='tasks__activity-info'>
                  <p className='tasks__activity-title'>
                    {elem.title}
                  </p>
                  <p className='tasks__activity-subtitle'>
                    {elem.short_description}
                  </p>
                </div>
                <div className='tasks__activity-coins'>
                  <img className='tasks__activity-coins-bg' src="assets/images/task-arrow.png" alt="" />
                  <p className='tasks__activity-coins-count'>+{elem.cost}</p>
                  <img className='tasks__activity-coin-img' src="assets/images/header-coin.png" alt="" />
                </div>
              </div>
            );
          })}
          <p style={{marginTop: '20px', width: '100%', maxWidth: 'none', boxSizing: 'border-box'}} className='kiosk__goods-info'>
            <img className='kiosk__goods-info-border' src="assets/images/rozigrizh-border.png" alt="" />
            <p className='tasks__goods-info-text'>
              1 пост = 1 активность
            </p>
          </p>
          </div>
          <div style={{display: navItem === 2 ? 'block' : 'none'}} className='tasks__mission-list'>
            {[...activities.task_list].map((elem, index) => {
              if (!elem.status) {
                return (<div className='tasks__mission'>
                  <div style={{marginRight: '9px', marginLeft: '-20px'}} className='header__count-wrapper'>
                    <img className='header__count-border' src="assets/images/hader-count-border.png" alt="" />
                    <p className='header__count-number'>
                      +{elem.cost}
                    </p>
                    <img className='header__coint' src="assets/images/header-coin.png" alt="" />
                  </div>
                  <p className='tasks__mission-text'>
                    {elem.title}
                  </p>
                  <div className='tasks__flag-wrapper'>
                    <img className='tasks__flag-img' src="assets/images/misson-flag-complete.png" alt="" />
                    <p style={{color: 'rgba(202, 19, 19, 1)'}} className='tasks__flag-text'>
                      ВЫПОЛНЕНО!
                    </p>
                  </div>
                </div>)
              }
              else {
                return (
                <div style={{backgroundColor: elem.light ? 'rgba(202, 19, 19, 0.27)' : 'rgba(0, 0, 0, 0.3)', border: elem.light ? '1px solid rgba(202, 19, 19, 1)' : '1px solid rgba(255, 255, 255, 0.2)', boxShadow: elem.light ? '0px 0px 25.5px 0px rgba(202, 19, 19, 1) inset' : 'none'}} className='tasks__mission'>
                  <div style={{marginRight: '9px', marginLeft: '-20px'}} className='header__count-wrapper'>
                    <img className='header__count-border' src="assets/images/hader-count-border.png" alt="" />
                    <p className='header__count-number'>
                      +{elem.cost}
                    </p>
                    <img className='header__coint' src="assets/images/header-coin.png" alt="" />
                  </div>
                  <p className='tasks__mission-text'>
                    {elem.title}
                  </p>
                  <div onClick={() => {
                    dispatch(setCurrentTask({
                      task_id: elem.task_id,
                      cost: elem.cost,
                      title: elem.title,
                      description: elem.description,
                      link: elem.link,
                      front_handler: elem.front_handler,
                      redirect: elem.redirect,
                    }))
                    if (elem.type === 'common') {
                      setSubPopup('tasks__sub-popup tasks__sub-popup_active')
                    }
                    if (elem.type === 'invite') {
                      setFriendsPopup('friends-popup_active friends-popup');
                    }
                    if (elem.type === 'common_with_time') {
                      setSubMonthPopup('tasks__sub-month tasks__sub-month_active');
                    }
                  }} className='tasks__flag-wrapper'>
                    <img className='tasks__flag-img' src="assets/images/mission-flag.png" alt="" />
                    <p className='tasks__flag-text'>
                      ВЫПОЛНИТЬ
                    </p>
                  </div>
                </div>)
              }
            })}
          </div>
        </div>
      </div>
    );
}

export default Tasks;