import React, { useEffect, useState } from 'react';
import './style.css';
import { useDispatch, useSelector } from 'react-redux';
import { setActivePanel, setIsActiveNavbar } from '../../store/mainReducer';

const Plug = () => {
  const dispatch = useDispatch();
  const [classPage, setClassPage] = useState('plug');

  
  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
    setTimeout(() => {
      setClassPage('plug plug_active');
    }, 240)
  }, [])
    return (
      <div className={`container ${classPage}`}>
        <div className='plug__content'>
          <img className='plug__img' src="assets/images/plug-img.png" alt="" />
          <p className='plug__text'>
          Для входа в&nbsp;Киоск&nbsp;Start<br /> 
          используй смартфон
          </p>
        </div>
      </div>
    );
}

export default Plug;