import React, { useEffect, useState } from 'react';
import './style.css';
import { useDispatch, useSelector } from 'react-redux';
import { setActivePanel, setIsActiveNavbar } from '../../store/mainReducer';

const Onboarding2 = () => {
  const dispatch = useDispatch();
  const [classPage, setClassPage] = useState('onboarding2');
  const [buttonText, setButtonText] = useState("Продолжить");
  const [step, setStep] = useState(1);

  useEffect(() => {
    if (step === 3) {
      setButtonText('Перейти в киоск');
    }
  }, [step])
  
  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
    setTimeout(() => {
      setClassPage('onboarding2 onboarding2_active');
    }, 240)
    return () => {
      dispatch(setIsActiveNavbar(true));
    }
  }, [])
    return (
      <div className={`container ${classPage}`}>
        <img className='onboarding2__logo' src="assets/images/start-logo.svg" alt="" />
        <div style={{display: step === 1 ? 'block' : 'none'}} className='onboarding2__info-block'>
          <img className='onboarding2__main-img' src="assets/images/onboardin1-main-img.png" alt="" />
          <div className='onboarding2__step'>
            <img className='onboarding2__step-border' src="assets/images/onboarding2-border.png" alt="" />
            <p className='onboarding2__step-text'>
              Выполняй
              задания
            </p>
          </div>
          <p className='onboarding2__first-block-subtitle'>
            Они несложные: отправить стикер, посмотреть серию любимого сериала, оформить подписку на&nbsp;START, написать комментарий... Стоит начать, и&nbsp;ты&nbsp;втянешься!
          </p>
        </div>
        <div style={{display: step === 2 ? 'block' : 'none'}} className='onboarding2__info-block'>
          <img className='onboarding2__coins-main' src="assets/images/onboarding2-coins-main.png" alt="" />
          <div className='onboarding2__step'>
            <img className='onboarding2__step-border' src="assets/images/onboarding2-border.png" alt="" />
            <p className='onboarding2__step-text'>
              Получай
              START-коины
            </p>
          </div>
          <p className='onboarding2__first-block-subtitle'>
            Это внутренняя валюта киоска. За&nbsp;каждое задание мы&nbsp;будем начислять определенную сумму. Пополнение кошелька займет какое-то время: терпение, спокойствие, всё будет!
          </p>
        </div>
        <div style={{display: step === 3 ? 'block' : 'none'}} className='onboarding2__info-block'>
          <img className='onboarding2__last-step-img' src="assets/images/onboarding2-last-step.png" alt="" />
          <div className='onboarding2__step'>
            <img className='onboarding2__step-border' src="assets/images/onboarding2-border-red.png" alt="" />
            <p className='onboarding2__step-text'>
              Участвуй в розыгрыше
            </p>
          </div>
          <p className='onboarding2__first-block-subtitle'>
            Призы разыграем среди тех, кто приобрел билеты: их&nbsp;можно будет купить за&nbsp;START-коины. Среди призов&nbsp;&mdash; Smart TV&nbsp;65&laquo;, PlayStation и&nbsp;сюрпризы для фанатов хитов START от&nbsp;&laquo;Против всех&raquo; и&nbsp;&laquo;Жить жизнь&raquo; до&nbsp;&laquo;Престижа&raquo; и&nbsp;&laquo;Папиных дочек&raquo;.
          </p>
        </div>
        <button onClick={() => {
          if (buttonText === 'Перейти в киоск') {
            setClassPage('onboarding1')
            setTimeout(() => {
              dispatch(setActivePanel('kiosk'))
            }, 240)
          }
          else {
            setStep((prevState) => prevState + 1);
          }
        }} className='onboarding2__next-btn'>
          {buttonText}
        </button>
        {/* <img className='onboarding2__bottom-img' src="assets/images/onboarding2-casset.png" alt="" /> */}
      </div>
    );
}

export default Onboarding2;