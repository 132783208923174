const api_url = 'https://kiosk.start.ru'


const initData = window.Telegram.WebApp.initData;

async function getUserInfo(url = '', tgId) {
    const queryParams = new URLSearchParams({
        user_id: tgId,
        initData: JSON.stringify(initData),
    }).toString();

    let request = `${api_url}${url}?${queryParams}`;

    let response = await fetch(request,
        {
            method: 'GET',
            credentials: 'include',
        });
    if (!response.ok) {
        throw new Error(`Неполучилось отправить POST запрос ${url}, статус ${response.status}`)
    }
    return await response.json();
}

async function getProducts(url = '', tgId) {
    
    const queryParams = new URLSearchParams({
        user_id: tgId,
        initData: JSON.stringify(initData),
    }).toString();

    let request = `${api_url}${url}?${queryParams}`;


    let response = await fetch(request,
        {
            method: 'GET',
            credentials: 'include',
        });
    if (!response.ok) {
        throw new Error(`Неполучилось отправить POST запрос ${url}, статус ${response.status}`)
    }
    return await response.json();
}
async function getUserProducts(url = '', tgId) {

    const queryParams = new URLSearchParams({
        user_id: tgId,
        initData: JSON.stringify(initData),
    }).toString();

    let request = `${api_url}${url}?${queryParams}`;

    let response = await fetch(request,
        {
            method: 'GET',
            credentials: 'include',
        });
    if (!response.ok) {
        throw new Error(`Неполучилось отправить POST запрос ${url}, статус ${response.status}`)
    }
    return await response.json();
}
async function getActivities(url = '', tgId) {
     const queryParams = new URLSearchParams({
        user_id: tgId,
        initData: JSON.stringify(initData),
    }).toString();

    let request = `${api_url}${url}?${queryParams}`;

    let response = await fetch(request,
        {
            method: 'GET',
            credentials: 'include',
        });
    if (!response.ok) {
        throw new Error(`Неполучилось отправить POST запрос ${url}, статус ${response.status}`)
    }
    return await response.json();
}
async function getFriends(url = '', tgId) {
    const queryParams = new URLSearchParams({
        user_id: tgId,
        initData: JSON.stringify(initData),
    }).toString();

    let request = `${api_url}${url}?${queryParams}`;

    let response = await fetch(request,
        {
            method: 'GET',
            credentials: 'include',
        });
    if (!response.ok) {
        throw new Error(`Неполучилось отправить POST запрос ${url}, статус ${response.status}`)
    }
    return await response.json();
}
async function getBurgerInfo(url = '', tgId) {
    const queryParams = new URLSearchParams({
        user_id: tgId,
        initData: JSON.stringify(initData),
    }).toString();

    let request = `${api_url}${url}?${queryParams}`;

    let response = await fetch(request,
        {
            method: 'GET',
            credentials: 'include',
        });
    if (!response.ok) {
        throw new Error(`Неполучилось отправить POST запрос ${url}, статус ${response.status}`)
    }
    return await response.json();
}
async function getPromoInfo(url = '', tgId) {
    const queryParams = new URLSearchParams({
        user_id: tgId,
        initData: JSON.stringify(initData),
    }).toString();

    let request = `${api_url}${url}?${queryParams}`;

    let response = await fetch(request,
        {
            method: 'GET',
            credentials: 'include',
        });
    if (!response.ok) {
        throw new Error(`Неполучилось отправить POST запрос ${url}, статус ${response.status}`)
    }
    return await response.json();
}
async function buyProduct(url = '', data = {}) {

    const queryParams = new URLSearchParams({
        initData: JSON.stringify(initData),
    }).toString();

    let request = `${api_url}${url}?${queryParams}`;

    let response = await fetch(request,
        {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(data)
        });
    if (!response.ok) {
        throw new Error(`Неполучилось отправить POST запрос ${url}, статус ${response.status}`)
    }
    return await response.json()
}
async function authRefresh(url = '', data = {}) {

    const queryParams = new URLSearchParams({
        initData: JSON.stringify(initData),
    }).toString();

    let request = `${api_url}${url}?${queryParams}`;

    let response = await fetch(request,
        {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(data)
        });
    if (!response.ok) {
        throw new Error(`Неполучилось отправить POST запрос ${url}, статус ${response.status}`)
    }
    return await response.json()
}
async function postStart() {

    let request = 'https://api.start.ru/auth/refresh?apikey=a20b12b279f744f2b3c7b5c5400c4eb5';

    let response = await fetch(request,
        {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'include',
            // headers: {
            //     'Content-Type': 'application/json',
            // },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            // body: ''
        });
    if (!response.ok) {
        throw new Error(`Неполучилось отправить POST запрос ${url}, статус ${response.status}`)
    }
    return await response.json()
}
async function postPromo(url = '', data = {}) {
    const queryParams = new URLSearchParams({
        initData: JSON.stringify(initData),
    }).toString();

    let request = `${api_url}${url}?${queryParams}`;

    let response = await fetch(request,
        {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(data)
        });
    if (!response.ok) {
        throw new Error(`Неполучилось отправить POST запрос ${url}, статус ${response.status}`)
    }
    return await response.json()
}



const ServerConnect = {
    getUser: getUserInfo,
    getProducts: getProducts,
    getUserProducts: getUserProducts,
    getActivities: getActivities,
    buyProduct: buyProduct,
    getFriends: getFriends,
    getBurgerInfo: getBurgerInfo,
    getPromoInfo: getPromoInfo,
    postPromo: postPromo,
    postStart: postStart,
    authRefresh: authRefresh,
    api_url: api_url,
}

export default ServerConnect;