import React, { useEffect, useState } from 'react';
import './style.css';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../Header/Header';
import loveIsExample from '../../../public/assets/images/static/love-is-example.png';
import ServerConnect from '../../service';
import { setFriends } from '../../store/mainReducer';

const Friends = () => {
  const dispatch = useDispatch();
  const [classPage, setClassPage] = useState('friends');
  const [snackbarClass, setSnackbarClass] = useState('snackbar');
  const invitedFriends = useSelector(state => state.main.userInfo.invitedFriends);
  const userInfo = useSelector(state => state.main.userInfo);
  const friends = useSelector(state => state.main.friends);

  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
    setTimeout(() => {
      setClassPage('friends friends_active');
    }, 240)

    ServerConnect.getFriends('/api/get_invites/', userInfo.tgId)
      .then((data) => {
        console.log(data);
        dispatch(setFriends(data));
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])
    return (
      <div className={`container ${classPage}`}>
        <div style={{zIndex: 100}} className={snackbarClass}>
          Ссылка скопирована
        </div>
        <Header />
        <img
          className="page-background"
          src="assets/images/main-bg.png"
          alt=""
        />
        <div className="friends__content">
          <div className='friends__no-friends'>
            <p className="friends__title">
              Друзья
              <span className="friends__count">{invitedFriends}</span>
            </p>
            <div className="friends__referral-block">
              <div className="friends__referral-top">
                <p className="friends__referral-text">
                  Пригласи
                  <br />
                  друзей
                </p>
                <div className="header__count-wrapper">
                  <img
                    className="header__count-border"
                    src="assets/images/hader-count-border.png"
                    alt=""
                  />
                  <p className="header__count-number">+200</p>
                  <img
                    className="header__coint"
                    src="assets/images/header-coin.png"
                    alt=""
                  />
                </div>
              </div>
              <div className="friends__referral-bottom">
                <button onClick={() => {
                  window.open(`https://t.me/share/url?url=${friends.link}`, '_blank');
                }} className="friends__referral-btn" type="button">
                  Пригласить
                </button>
                <div onClick={() => {
                  navigator.clipboard.writeText(friends.link);
                  setSnackbarClass('snackbar snackbar_active');
                  setTimeout(() => {
                    setSnackbarClass("snackbar");
                  }, 2000)
                }} className="friends__referral-copy-btn">
                  <img
                    className="friends__referral-copy-img"
                    src="assets/images/copy-icon.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className='friends__list'>
              {[...friends.invite_list.map((elem, index) => {
                return (
                  <div key={index} className='friends__item'>
                    <img className='friends__item-icon' src="assets/images/user-icon.svg" alt="" />
                    <p className='friends__item-name'>
                      {elem.username !== null ? elem.username : elem.user_id}
                    </p>
                    <div style={{marginLeft: 'auto'}} className="header__count-wrapper">
                      <img
                        className="header__count-border"
                        src="assets/images/hader-count-border.png"
                        alt=""
                      />
                      <p className="header__count-number">+{elem.cost}</p>
                      <img
                        className="header__coint"
                        src="assets/images/header-coin.png"
                        alt=""
                      />
                    </div>
                  </div>
                )
              })]}
            </div>
          </div>
        </div>
      </div>
    );
}

export default Friends;