import {createSlice} from "@reduxjs/toolkit";

const mainSlice = createSlice({
    name: 'main',
    initialState: {
        platform: null,
        activePanel: '',
        tgID: 0,
        isActiveNavbar: false,
        userInfo: {},
        bubbles: [],
        products: [],
        userProducts: [],
        currentProduct: {},
        currentTicketInfo: [],
        currentTicketTitle: '',
        subInstruction: {},
        activities: {
            active_list: [],
            task_list: [],
            daily_list: [],
            current_daily: '',
            current_daily_received: '',
        },
        friends: {
            cost: 0,
            link: '',
            invite_list: [],
        },
        currentTask: {
            task_id: 0,
            cost: 0,
            title: '',
            description: '',
            link: '',
            front_handler: '',
            redirect: '',
        },
        burgerInfo: {
            "promo": {
                "status_found": true,
                "text": "Промо",
                "image": "",
                "link": ""
                },
            "history": {
                "text": "История начислений",
                "history_list": [
                    
                ]
            },
            "questions": {
                "text": "",
                "link": ""
            },
            "rules": {
                "text": "",
                "link": ""
            },
            "kiosk_logics": {
                "text": "",
                "link": ""
            }
        },
        promoInfo: {
            "status_found": false,
            "promo_id": 1,
            "text": "",
            "image": "",
            "link": "",
            "button_text": "",
            "to_app_text": ""
        },
        firstUse: true,
    },
    reducers: {
        setActivePanel(state, action) {
            state.activePanel = action.payload;
        },
        setIsActiveNavbar(state, action) {
            state.isActiveNavbar = action.payload;
        },
        setTgId(state, action) {
            state.tgID = action.payload;
        },
        setUserInfo(state, action) {
            state.userInfo = action.payload;
        },
        setBubbles(state, action) {
            state.bubbles = action.payload;
        },
        setProducts(state, action) {
            state.products = action.payload;
        },
        setUserProducts(state, action) {
            state.userProducts = action.payload;
        },
        setCurrentProduct(state, action) {
            state.currentProduct = action.payload;
        },
        setSubInstruction(state, action) {
            state.subInstruction = action.payload;
        },
        setActivities(state, action) {
            state.activities = action.payload;
        },
        setFriends(state, action) {
            state.friends = action.payload;
        },
        setCurrentTask(state, action) {
            state.currentTask = action.payload;
        },
        setBurgerInfo(state, action) {
            state.burgerInfo = action.payload;
        },
        setPromoInfo(state, action) {
            state.promoInfo = action.payload;
        },
        setCurrentTicketInfo(state, action) {
            state.currentTicketInfo = action.payload;
        },
        setCurrentTicketTitle(state, action) {
            state.currentTicketTitle = action.payload;
        },
        setFirstUse(state, action) {
            state.firstUse = action.payload;
        }
    }
})

export default mainSlice.reducer
export const {
    setActivePanel,
    setIsActiveNavbar,
    setTgId,
    setUserInfo,
    setBubbles,
    setProducts,
    setCurrentProduct,
    setUserProducts,
    setSubInstruction,
    setActivities,
    setFriends,
    setCurrentTask,
    setBurgerInfo,
    setPromoInfo,
    setCurrentTicketInfo,
    setCurrentTicketTitle,
    setFirstUse,
} = mainSlice.actions