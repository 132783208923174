import React, { useEffect, useState } from 'react';
import './style.css';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../Header/Header';
import ServerConnect from '../../service';
import { setBurgerInfo } from '../../store/mainReducer';

const Burger = ({historyClick, setHistoryClick}) => {
  const dispatch = useDispatch();
  const [classPage, setClassPage] = useState('burger');

  const [openIndex, setOpenIndex] = useState(null); 

  const toggleAccordion = (index) => {
    // Если текущий индекс уже открыт, закрываем его, иначе открываем новый
    setOpenIndex(openIndex === index ? null : index);
};

  // const [historyClick, setHistoryClick] = useState(false);

  const userInfo = useSelector(state => state.main.userInfo);
  const burgerInfo = useSelector(state => state.main.burgerInfo);

  function formatDate(time) {
    const date = new Date(time);

    // Функция для добавления ведущего нуля
    const pad = (num) => (num < 10 ? '0' + num : num);
    
    // Форматируем дату
    return `${pad(date.getDate())}.${pad(date.getMonth() + 1)}.${date.getFullYear()} ${pad(date.getHours())}:${pad(date.getMinutes())}`;
  }

  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
    setTimeout(() => {
      setClassPage('burger burger_active');
    }, 240)

    ServerConnect.getBurgerInfo('/api/get_faq_and_history/', userInfo.tgId)
      .then((data) => {
        console.log(data);
        dispatch(setBurgerInfo(data))
        // setActiveHistory(data.history.history_list);
      })
      .catch((err) => {
        console.log(err);
      })
  }, [])
    return (
      <div className={`container ${classPage}`}>
        <img
          className="page-background"
          src="assets/images/main-bg.png"
          alt=""
        />
        <Header historyClick={historyClick} setHistoryClick={setHistoryClick}/>
        <div style={{display: !historyClick ? 'block' : 'none'}} className='burger__content'>
          <div onClick={() => {
            setHistoryClick(true);
          }} className='burger__button'>
            {burgerInfo.history.text}
          </div>
          {/* <div onClick={() => {
                  window.open(burgerInfo.kiosk_logics.link, '_blank');
                }} className='burger__button'>
            {burgerInfo.kiosk_logics.text}
          </div> */}
          <div onClick={() => {
                  window.open(burgerInfo.questions.link, '_blank');
                }} className='burger__button'>
            {burgerInfo.questions.text}
          </div>
          <div onClick={() => {
                  window.open(burgerInfo.rules.link, '_blank');
                }} className='burger__button'>
            {burgerInfo.rules.text}
          </div>
          <img className='burger__banner' src="assets/images/burger-banner-3.png" alt="" />
        </div>
        <div style={{display: historyClick ? 'block' : 'none'}} className='burger__history'>
          <div className='burger__history-content'>
            {[...burgerInfo.history.history_list].map((elem, index) => {
              return (
              <div key={index} className='burger__item'>
                <div onClick={() => toggleAccordion(index)} className='burger__item-action'>
                  <p className='burger__action-name'>
                    {elem.name}
                  </p>
                  <div className='burger__action-coins-wrapper'>
                    <img className='burger__action-bg' src={elem.cost > 0 ? "assets/images/history-arrow-bg.png" : 'assets/images/history-arrow-bg-minus.png'} alt="" />
                    <p className='burger__action-count'>
                      {elem.cost > 0 ? `+${elem.cost}` : elem.cost}
                    </p>
                    <img className='burger__action-coin' src="assets/images/header-coin.png" alt="" />
                  </div>
                  <img style={{transform: openIndex !== index ? 'rotate(0)' : ''}} className='burger__action-arrow' src="assets/images/burger-action-arrow.svg" alt="" />
                </div>
                <div style={{display: openIndex === index ? 'flex' : 'none', alignItems: 'center', gap: '15px', justifyContent: 'space-between'}} className='burger__item-info'>
                  <div className='burger__item-date-wrapper'>
                    <p className='burger__info-title'>
                      Дата и время
                    </p>
                    <p className='burger__info-date'>
                      {formatDate(elem.datetime)}
                    </p>
                  </div>
                  <div style={{display: elem.type === 'ticket_finish_handler' ? 'block' : 'none' }} className='burger__item-date-wrapper'>
                    <p className='burger__info-title'>
                      Кол-во билетов
                    </p>
                    <p className='burger__info-date'>
                      {elem.count}
                    </p>
                  </div>
                </div>
              </div>
              )
            })}
          </div>
        </div>
      </div>
    );
}

export default Burger;