import React, { useEffect, useState } from 'react';
import './style.css';
import { useDispatch, useSelector } from 'react-redux';
import { setActivePanel } from '../../store/mainReducer';

const Onboarding1 = () => {
  const dispatch = useDispatch();
  const [classPage, setClassPage] = useState('onboarding1');
  const firstUse = useSelector(state => state.main.firstUse);
  
  useEffect(() => {
    window.scrollTo({top: 0, behavior: 'smooth'});
    setTimeout(() => {
      setClassPage('onboarding1 onboarding1_active');
    }, 240)
  }, [])
    return (
      <div className={`container ${classPage}`}>
        <div className='onboarding1__banner-wrapper'>
          {/* <img className='onboarding1__banner' src="assets/images/onboarding1-banner.png" alt="" /> */}
          <img className='onboarding1__zina' src="assets/images/onboarding-1-actors.png" alt="" />
          <img className='onboarding1__shadow' src="assets/images/onboarding1-shadow.png" alt="" />
          <img style={{transform: 'rotate(180deg) scaleX(-1)'}} className='onboarding1__shadow2' src="assets/images/onboarding1-shadow.png" alt="" />
        </div>
        <p className='onboarding1__title'>
          Выиграй призы от START: от&nbsp;PlayStation до&nbsp;Smart&nbsp;TV
        </p>
        <p className='onboarding1__subtitle'>
          А&nbsp;еще разыгрываем постеры с&nbsp;автографами, персональные голосовые от&nbsp;актеров, редкие фото и&nbsp;много других штук. Активное участие и&nbsp;немного удачи&nbsp;&mdash; и&nbsp;они твои!
        </p>
        <button onClick={() => {
          if (!firstUse) {
            setClassPage('onboarding1')
            setTimeout(() => {
              dispatch(setActivePanel('onboarding-2'))
            }, 240)
          }
          else {
            setClassPage('onboarding1')
            setTimeout(() => {
              dispatch(setActivePanel('kiosk'))
            }, 240)
          }
        }} className='onboarding1__next-btn'>
          Продолжить
        </button>
      </div>
    );
}

export default Onboarding1;