import React, { useEffect } from 'react';
import './style.css';
import { useDispatch, useSelector } from 'react-redux';
import { setActivePanel } from '../../store/mainReducer';

const Header = ({historyClick, setHistoryClick}) => {
  const dispatch = useDispatch();
  const points = useSelector(state => state.main.userInfo.points);

    return (
        <div className='header'>
          <img onClick={() => {
            if (historyClick) {
              setHistoryClick(false);
            }
            else {
              dispatch(setActivePanel('burger'))
            }
            }} style={{width: historyClick ? '23px' : '26px'}} className='header__burger-icon' src={historyClick ? 'assets/images/back-arrow.svg' : 'assets/images/header-burger.png'} alt="" />
          <img className='header__logo' src="assets/images/header-logo.png" alt="" />
          <div className='header__count-wrapper'>
            <img className='header__count-border' src="assets/images/hader-count-border.png" alt="" />
            <p className='header__count-number'>
              {points}
            </p>
            <img className='header__coint' src="assets/images/header-coin.png" alt="" />
          </div>
        </div>
    );
}

export default Header;