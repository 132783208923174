import React, { useEffect, useState } from 'react';
import './style.css';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../Header/Header';
import loveIsExample from '../../../public/assets/images/static/love-is-example.png';
import ServerConnect from '../../service';
import ShaderProgram from '../../ShaderProgram.js';
import { setActivePanel, setActivities, setCurrentProduct, setCurrentTicketInfo, setCurrentTicketTitle, setIsActiveNavbar, setProducts, setPromoInfo, setSubInstruction, setUserInfo, setUserProducts } from '../../store/mainReducer';

const Kiosk = ({backTicketInfo}) => {
  const dispatch = useDispatch();
  const [classPage, setClassPage] = useState('kiosk');
  const userInfo = useSelector(state => state.main.userInfo);
  const products = useSelector(state => state.main.products);
  const bubbles = useSelector(state => state.main.bubbles);
  // const bubbles = ['баббл1', 'баббл2']
  const userProducts = useSelector(state => state.main.userProducts);
  const currentProduct = useSelector(state => state.main.currentProduct);
  const subInstruction = useSelector(state => state.main.subInstruction);
  const promoInfo = useSelector(state => state.main.promoInfo);

  const [goodPopup, setGoodPopup] = useState('good-popup');
  const [goodBuyPopup, setGoodBuyPopup] = useState("goodBuy-popup");
  const [goodCount, setGoodCount] = useState(1);
  const [disabledBtn, setDisabledBtn] = useState(false);

  const [subscribePopup, setSubscribePopup] = useState("subscribe-popup");

  const [debtPopup, setDebtPopup] = useState('debt-popup');

  const [subInfoPopup, setSubInfoPopup] = useState('subInfo-popup');

  const [snackbarClass, setSnackbarClass] = useState('snackbar');

  const [navItem, setNavItem] = useState(backTicketInfo ? 2 : 1);

    const imagesArray = ["assets/images/kiosk-gavr-1.png", "assets/images/kiosk-gavr-2.png", "assets/images/kiosk-gavr-3.png"]
    const [currentKioskImg, setCurrentKioskImg] = useState("assets/images/kiosk-gavr-1.png");

    const changeBubbleTime = 13000;
    const showBubbleTime = bubbles.length == 1 ? 12500 : 5000;
    const [currentBubble, setCurrentBubble] = useState(bubbles[0])
    const [bubbleClass, setBubbleClass] = useState("kiosk__zina-text-wrapper");
    useEffect(() => {
      setTimeout(() => {
        console.log('hide bubble')
        setBubbleClass('kiosk__zina-text-wrapper kiosk__zina-text-wrapper_disable')
      }, showBubbleTime)

      const updateImage = () => {
        const randomIndex = Math.floor(Math.random() * imagesArray.length);
        setCurrentKioskImg(imagesArray[randomIndex])
      }
      const intervalId = setInterval(updateImage, 13000);

      return () => clearInterval(intervalId);
    }, [])
    useEffect(() => {
      // Функция для обновления currentBubble
      const updateBubble = () => {
          console.log('show bubble')
          setBubbleClass('kiosk__zina-text-wrapper')
          setTimeout(() => {
            console.log('hide bubble')
            setBubbleClass('kiosk__zina-text-wrapper kiosk__zina-text-wrapper_disable')
          }, showBubbleTime)
          const randomIndex = Math.floor(Math.random() * bubbles.length);
          setCurrentBubble(bubbles[randomIndex]);
      };

      // Устанавливаем интервал для обновления
      const intervalId = setInterval(updateBubble, changeBubbleTime);

      // Очищаем интервал при размонтировании компонента
      return () => clearInterval(intervalId);
  }, [changeBubbleTime]); 

  useEffect(() => {
    dispatch(setIsActiveNavbar(true));
    window.scrollTo({top: 0, behavior: 'smooth'});
    setTimeout(() => {
      setClassPage('kiosk kiosk_active');
    }, 240)

    ServerConnect.getProducts('/api/get_products/', userInfo.tgId)
      .then((data) => {
        console.log(data);
        dispatch(setProducts(data.product_list));
      })
      .catch((err) => {
        console.log(err);
      })
    
  }, [])

  useEffect(() => {
    if (userInfo.points >= currentProduct.cost * goodCount) {
      setDisabledBtn(false)
    }
    if (userInfo.points < currentProduct.cost * goodCount) {
      setDisabledBtn(true)
    }
  }, [goodCount])

  const [promoPopup, setPromoPopup] = useState('promo');


  useEffect(() => {
    ServerConnect.getPromoInfo('/api/get_promo_material/', userInfo.tgId)
      .then((data) => {
        console.log(data);
        dispatch(setPromoInfo(data));
        if (data.status_found) {
          setPromoPopup('promo promo_active');
        }
      })
      .catch(err => console.log(err));
  }, []);


    return (
      <div className={`container ${classPage}`}>
        <div className={promoPopup}>
          <img className='promo__banner' src={promoInfo.image} alt="" />
          <p className='promo__text'>
            {promoInfo.text}
          </p>
          <button onClick={() => {
            setPromoPopup('promo');
            window.open(promoInfo.link, '_blank');
            ServerConnect.postPromo('/api/user_promo_view/', {
              user_id: userInfo.tgId,
              promo_id: promoInfo.promo_id
            })
              .then((data) => {
                console.log(data);
              })
              .catch(err => console.log(err));
          }} className='promo__link-btn' type='button'>
            {promoInfo.button_text}
          </button>
          <button onClick={() => {
            setPromoPopup('promo');
            ServerConnect.postPromo('/api/user_promo_skip/', {
              user_id: userInfo.tgId,
              promo_id: promoInfo.promo_id
            })
              .then((data) => {
                console.log(data);
              })
              .catch(err => console.log(err));
          }} className='promo__close-btn' type='button'>
            {promoInfo.to_app_text}
          </button>
        </div>
        <div className={subInfoPopup}>
          <div className={snackbarClass}>
            Код скопирован
          </div>
          <img onClick={() => setSubInfoPopup('subInfo-popup')} className='subInfo-popup__close' src="assets/images/close-icon.svg" alt="" />
          <p className='subInfo-popup__title'>
            {subInstruction.title}
          </p>
          <p className='subInfo-popup__subtitle'>
            {subInstruction.text}
          </p>
          <div onClick={() => {
            navigator.clipboard.writeText(subInstruction.code);
            setSnackbarClass('snackbar snackbar_active');
            setTimeout(() => {
              setSnackbarClass('snackbar');
            }, 2000)
            }} className='subInfo-popup__promo-wrapper'>
            <div className='subInfo-popup__promo-code'>
              {subInstruction.code}
            </div>
            <img className='subInfo-popup__promo-copy' src="assets/images/copy-icon.svg" alt="" />
          </div>
          <button onClick={() => {         
            window.open(subInstruction.link, '_blank');
          }} className='subInfo-popup__btn' type='button'>
            <p className='subInfo-popup__btn-text'>
              Активировать
            </p>
            <img className='subInfo-popup__btn-arrow' src="assets/images/link-arrow.svg" alt="" />
          </button>
        </div>
        <div className={debtPopup}>
          <div className='debt-popup__info'>
            <img className='debt-popup__dialog' src="assets/images/debt-dialog.png" alt="" />
            <p className='debt-popup__title'>
              В долг<br />
              не даем!
            </p>
            <p className='debt-popup__subtitle'>
              Подкопи START-коинов и&nbsp;приходи позже, или присмотри что-нибудь подешевле
            </p>
          </div>
          <img className='debt-popup__zina' src="assets/images/debt-popup-zina.png" alt="" />
          <button onClick={() => {
            setDebtPopup('debt-popup');
            setClassPage('kiosk');
            setTimeout(() => {
              dispatch(setActivePanel('tasks'))
            }, 200)
          }} className='debt-popup__how-btn' type='button'>
            Заработать больше START-коинов
          </button>
          <button onClick={() => setDebtPopup('debt-popup')} className='debt-popup__back-btn' type='button'>
            Найти товары подешевле
          </button>
        </div>
        <div className={subscribePopup}>
        <div className='good-popup__info'>
            <img className='good-popup__info-border' src="assets/images/kiosk-portugal-border.png" alt="" />
            <p className='good-popup__info-title'>
              {currentProduct.title}
            </p>
            <img className='good-popup__info-img' src={currentProduct.image} alt="" />
            <div className='good-popup__red-border'></div>
            <p className='good-popup__info-count-title'>
              Билет за
            </p>
            <div className='good-popup__info-count-wrapper'>
              <p className='good-popup__info-count-text'>{currentProduct.cost}</p>
              <img className='good-popup__info-count-coin' src="assets/images/header-coin.png" alt="" />
            </div>
          </div>
          <p className='good-popup__short-description'>
            {currentProduct.short_description === null ? '' : currentProduct.short_description}
          </p>
          <button onClick={() => {
            ServerConnect.buyProduct('/api/post_buy_product/', {
              user_id: userInfo.tgId,
              product_id: currentProduct.product_id,
              count: 1,
            })
            .then((data) => {
              if (data.status === 'Purchase confirmed') {
                setSubscribePopup('subscribe-popup');
                setTimeout(() => {
                  setGoodBuyPopup('goodBuy-popup goodBuy-popup_active');
                }, 200)
              }
              if (data.status === 'Insufficient points') {
                setDebtPopup('debt-popup debt-popup_active')
              }
              console.log(data);
              ServerConnect.getProducts('/api/get_products/', userInfo.tgId)
                .then((data) => {
                  console.log(data);
                  dispatch(setProducts(data.product_list));
                })
                .catch((err) => {
                  console.log(err);
                })
              ServerConnect.getUser('/api/get_user_data/', userInfo.tgId)
              .then((data) => {
                console.log(data);
                dispatch(setUserInfo({
                  tgId: data.user_data.user_id,
                  username: data.user_data.username,
                  points: data.user_data.points,
                  invitedFriends: data.referral_count,
                }));
              })
              .catch(err => console.log(err))
            })
            .catch((err) => {
              console.log(err);
            })
          }} disabled={disabledBtn} style={{opacity: disabledBtn ? '.4' : '1'}} className='subscribe-popup__buy-btn' type='button'>
            Купить
          </button>
          <button onClick={() => setSubscribePopup('subscribe-popup')} className='subscribe-popup__back-btn' type='button'>
            Отложить покупку
          </button>
        </div>
        <div className={goodPopup}>
          <div className='good-popup__info'>
            <img className='good-popup__info-border' src="assets/images/kiosk-portugal-border.png" alt="" />
            <p className='good-popup__info-title'>
              {currentProduct.title}
            </p>
            <img className='good-popup__info-img' src={currentProduct.image} alt="" />
            <div className='good-popup__red-border'></div>
            <p className='good-popup__info-count-title'>
              Билет за
            </p>
            <div className='good-popup__info-count-wrapper'>
              <p className='good-popup__info-count-text'>{currentProduct.cost}</p>
              <img className='good-popup__info-count-coin' src="assets/images/header-coin.png" alt="" />
            </div>
          </div>
          <p className='good-popup__short-description'>
            {currentProduct.short_description === null ? '' : currentProduct.short_description}
          </p>
          <p style={{marginBottom: '13px', width: 'calc(100% - 40px)', maxWidth: 'none'}} className='kiosk__goods-info'>
            <img className='kiosk__goods-info-border' src="assets/images/rozigrizh-border.png" alt="" />
            <p className='kiosk__goods-info-text'>
              {currentProduct.prize_draw_date}
            </p>
          </p>
          <div className='good-popup__buttons'>
            <div className='good-popup__top-buttons'>
              <div className='good-popup__plus-minus'>
                <div onClick={() => {
                  if (goodCount >= 2) {
                    setGoodCount((prevState) => prevState - 1);
                  } 
                  }} className='good-popup__minus'>
                  <div></div>
                </div>
                <div className='good-popup__plus-minus-count'>{goodCount}</div>
                <div onClick={() => {
                  setGoodCount((prevState) => prevState + 1);
                }} className='good-popup__plus'>
                  <img src="assets/images/good-popup-plus.svg" alt="" />
                </div>
              </div>
              <button onClick={() => {
                ServerConnect.buyProduct('/api/post_buy_product/', {
                  user_id: userInfo.tgId,
                  product_id: currentProduct.product_id,
                  count: goodCount,
                })
                .then((data) => {
                  setGoodCount(1);
                  if (data.status === 'Purchase confirmed') {
                    setGoodPopup('good-popup');
                    setTimeout(() => {
                      setGoodBuyPopup('goodBuy-popup goodBuy-popup_active');
                    }, 200)
                  }
                  if (data.status === 'Insufficient points') {
                    setDebtPopup('debt-popup debt-popup_active')
                  }
                  console.log(data);
                  ServerConnect.getProducts('/api/get_products/', userInfo.tgId)
                    .then((data) => {
                      console.log(data);
                      dispatch(setProducts(data.product_list));
                    })
                    .catch((err) => {
                      console.log(err);
                    })
            
                  ServerConnect.getUser('/api/get_user_data/', userInfo.tgId)
                  .then((data) => {
                    console.log(data);
                    dispatch(setUserInfo({
                      tgId: data.user_data.user_id,
                      username: data.user_data.username,
                      points: data.user_data.points,
                      invitedFriends: data.referral_count,
                    }));
                  })
                  .catch(err => console.log(err))
                })
                .catch((err) => {
                  console.log(err);
                })
              }} disabled={disabledBtn} style={{opacity: disabledBtn ? '.4' : '1'}} className='good-popup__buy' type='button'>
                <p className='good-popup__buy-text'>Купить за {currentProduct.cost * goodCount}</p>
                <img className='good-popup__buy-coin' src="assets/images/header-coin.png" alt="" />
              </button>
            </div>
            <div onClick={() => {
              setGoodPopup('good-popup');
              setTimeout(() => {
                setGoodCount(1);
              }, 250)
              }} className='good-popup__exit'>
              Отложить покупку
            </div>
          </div>
        </div>
        <div className={goodBuyPopup}>
          <div className='goodBuy-popup__content'>
            <img className='goodBuy-popup__img' src="assets/images/goodBuy-hand-2.png" alt="" />
            <p className='goodBuy-popup__title'>
              Билет&nbsp;&mdash; твой!
            </p>
            <p className='goodBuy-popup__subtitle'>
              Больше билетов&nbsp;&mdash; больше шансов выиграть суперпризы. Копи START-коины, закупайся в&nbsp;ларьке и&nbsp;участвуй в&nbsp;розыгрыше суперпризов.
            </p>
            <button onClick={() => setGoodBuyPopup('goodBuy-popup')} className='goodBuy-popup__back-button' type='button'>
              Вернуться к прилавку
            </button>
            <button onClick={() => {
              setGoodBuyPopup('goodBuy-popup');
              setClassPage('kiosk');
              setTimeout(() => {
                dispatch(setActivePanel('tasks'))
              }, 200)
            }} className='goodBuy-popup__question-button' type='button'>
              Заработать больше START-коинов
            </button>
          </div>
        </div>
        <Header />
        <img className='page-background' src="assets/images/main-bg.png" alt="" />
        <div className='kiosk__content'>
          <div className='kiosk__zina-wrapper'>
            <img className='kiosk__zina' src={bubbles.length == 0 ? 'assets/images/uchet1.png' : currentKioskImg} alt="" />
            <div style={{display: bubbles.length == 0 ? 'none' : ''}} className={bubbleClass}>
              <img className='kiosk__zina-text-image' src="assets/images/kiosk-zina-text.png" alt="" />
              <p className='kiosk__zina-text'>
                {currentBubble}
              </p>
            </div>
          </div>
          <div className='kiosk__navbar'>
            <div style={{padding: '13px 10px'}} onClick={() => {
              if (navItem != 1) {
                ServerConnect.getProducts('/api/get_products/', userInfo.tgId)
                .then((data) => {
                  console.log(data);
                  dispatch(setProducts(data.product_list));
                })
                .catch((err) => {
                  console.log(err);
                })
              }
              setNavItem(1);
              }} className={navItem === 1 ? 'kiosk__nav-item kiosk__nav-item_active' : 'kiosk__nav-item'} >
              Сегодня в продаже
            </div>
            <div onClick={() => {
              if (navItem !== 2) {
                ServerConnect.getUserProducts('/api/get_user_product/', userInfo.tgId)
                .then((data) => {
                  console.log(data);
                  dispatch(setUserProducts(data.user_product_list))
                })
                .catch((err) => {
                  console.log(err);
                })
              }
              setNavItem(2);
              }} className={navItem === 2 ? 'kiosk__nav-item kiosk__nav-item_active' : 'kiosk__nav-item'}>
              Мои покупки
            </div>
          </div>
          <div style={{display: navItem === 1 ? 'block': 'none'}} className='kiosk__goods'>
            <p style={{display: 'none'}} className='kiosk__goods-info'>
                <img className='kiosk__goods-info-border' src="assets/images/rozigrizh-border.png" alt="" />
                <p className='kiosk__goods-info-text'>
                  Розыгрыш призов состоится 27&nbsp;декабря
                </p>
            </p>
            <div className='kiosk__goods-list'>
              {[...products].map((elem, index) => {
                return(
                  <div onClick={() => {
                    dispatch(setCurrentProduct({
                      product_id: elem.product_id,
                      cost: elem.cost,
                      title: elem.title,
                      description: elem.description,
                      image: elem.image,
                      counter_flag: elem.counter_flag,
                      count_for_user: elem.count_for_user,
                      infinity: elem.infinity,
                      short_description: elem.short_description,
                      prize_draw_date: elem.prize_draw_date,
                    }))
                    if ((!elem.infinity && elem.count_for_user === 0)) {
                      setDisabledBtn(true);
                    }
                    else {
                      setDisabledBtn(false);
                    }
                    if (userInfo.points >= elem.cost && elem.on_sale) {
                      if (elem.counter_flag) {
                        setGoodPopup('good-popup good-popup_active')
                      }
                      else {
                        setSubscribePopup('subscribe-popup subscribe-popup_active');
                      }
                    }
                    else {
                      if (elem.on_sale) {
                        setDebtPopup('debt-popup debt-popup_active');
                      }
                    }
                    }} key={index} className='kiosk__goods-item'>
                    <img className='kiosk__goods-item-border' src={elem.serial_type == 'gavr' ? 'assets/images/goods-border-gavr.png' : elem.serial_type == 'rybinsk' ? 'assets/images/goods-border-rybinsk.png' : 'assets/images/goods-border.png'} alt="" />
                    <div className='kiosk__goods-img-wrapper'>
                      <img className='kiosk__goods-item-img' src={elem.image} alt="" />
                    </div>
                    <p className='kiosk__goods-item-title'>
                      {elem.title}
                    </p>
                    <p className='kiosk__goods-item-subtitle'>
                      {elem.description}
                    </p>
                    <div className='good-item__count-wrapper'>
                      <img className='good-item__count-border' src={userInfo.points >= elem.cost ? 'assets/images/hader-count-border.png' : 'assets/images/header-count-border-gray.png'} alt="" />
                      <p style={{opacity: userInfo.points >= elem.cost ? '1' : '.5'}} className='good-item__count-number'>
                        {!elem.on_sale ? 'скоро' : elem.cost}
                      </p>
                      <img style={{opacity: userInfo.points >= elem.cost ? '1' : '.5', display: !elem.on_sale ? 'none' : 'block'}} className='good-item__coint' src="assets/images/header-coin.png" alt="" />
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
          <div style={{display: navItem === 2 ? 'block': 'none'}} className='kiosk__purchases'>
              <p style={{display: 'none'}} className='kiosk__goods-info'>
                <img className='kiosk__goods-info-border' src="assets/images/rozigrizh-border.png" alt="" />
                <p className='kiosk__goods-info-text'>
                  Розыгрыш призов состоится 27&nbsp;декабря
                </p>
              </p>
              <div style={{display: userProducts.length === 0 ? 'flex': 'none'}} className='kiosk__no-purchases'>
                <img className='kiosk__no-purchases-img' src="assets/images/no-purchases-casset.png" alt="" />
                <p className='kiosk__no-purchases-title'>
                  Пока здесь нет билетов
                </p>
                <p className='kiosk__no-purchases-subtitle'>
                  Приглашай друзей, смотри фильмы и&nbsp;сериалы, оставляй комментарии&nbsp;&mdash; и&nbsp;зарабатывай. START-коины помогут вытащить счастливый билетик.
                </p>
              </div>
              <div style={{display: userProducts.length !== 0 ? 'grid': 'none'}} className='kiosk__purchases-list'>
                {[...userProducts].map((elem, index) => {
                  if (elem.count === null) {
                    return(
                      <div onClick={() => {
                          dispatch(setSubInstruction(elem.instruction));
                          setSubInfoPopup('subInfo-popup subInfo-popup_active');
                        }} key={index} className='kiosk__goods-item'>
                        <img className='kiosk__goods-item-border' src={elem.serial_type == 'gavr' ? 'assets/images/goods-border-gavr.png' : elem.serial_type == 'rybinsk' ? 'assets/images/goods-border-rybinsk.png' : 'assets/images/goods-border.png'} alt="" />
                        <img className='kiosk__goods-item-logo' src={elem.image} alt="" />
                        <p className='kiosk__goods-item-text'>
                          {elem.title}
                        </p>
                        <div className='good-item__count-wrapper'>
                          <img className='good-item__count-border' src='assets/images/hader-count-border.png' alt="" />
                          <p className='good-item__count-about'>Подробнее</p>
                        </div>
                      </div>
                    )
                  }
                  else {
                    return(
                      <div onClick={() => {
                        dispatch(setCurrentTicketInfo(elem.tickets));
                        dispatch(setCurrentTicketTitle(elem.title));
                        setClassPage('kiosk');
                        setTimeout(() => {
                          dispatch(setActivePanel('ticket-info'));
                        }, 200)
                      }} key={index} className='kiosk__goods-item'>
                        <img className='kiosk__goods-item-border' src={elem.serial_type == 'gavr' ? 'assets/images/goods-border-gavr.png' : elem.serial_type == 'rybinsk' ? 'assets/images/goods-border-rybinsk.png' : 'assets/images/goods-border.png'} alt="" />
                        <div className='kiosk__goods-img-wrapper'>
                          <img className='kiosk__goods-item-img' src={elem.image} alt="" />
                          {/* <img className='kiosk__goods-item-ticket' src="assets/images/kiosk-ticket-icon.svg" alt="" /> */}
                        </div>
                        <p className='kiosk__goods-item-title'>
                          {elem.title}
                        </p>
                        <p className='kiosk__goods-item-subtitle'>
                          {elem.description}
                        </p>
                        <div className='good-item__count-wrapper'>
                          <img className='good-item__count-border' src='assets/images/header-count-border-gray.png' alt="" />
                          <p className='good-item__count-number'>
                            {elem.count}
                          </p>
                          <img className='good-item__coint' src="assets/images/ticket-icon.svg" alt="" />
                        </div>
                      </div>
                    )
                  }
                })}
              </div>
          </div>
        </div>
      </div>
    );
}

export default Kiosk;